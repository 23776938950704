<template>
  <form action="/">
    <van-search v-model="searchKey" placeholder="输入邀请者电话进行搜索" @clear="onClear" @search="onSearch" />
  </form>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <div style="padding:15px;margin:15px 0 0 0;background:#fff;" v-for="item in list" :key="item">
      <div class="invite-item">
        <h5>邀请者(ID:{{item.inviter.buyerId}})：</h5>
        <div class="user">
          <img class="avatar" :src="item.inviter.user.avatar" alt="">
          <div class="name">
            <div>{{ item.inviter.name }}({{ item.inviter.user.phone }})</div>
            <p>
              <span>累计收益: ¥{{item.inviter.user.profit}}</span>
              <span>余额: ¥{{item.inviter.user.balance}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="invite-item" @click="onClick(item)" style="margin-top: 15px; border-top: 1px solid #f6f6f6; padding-top: 10px;">
        <h5>受邀者(ID:{{item.invitee.buyerId}})：</h5>
        <div class="user">
          <img class="avatar" :src="item.invitee.user.avatar" alt="">
          <div class="name">
            <div>{{ item.invitee.name }}({{ item.invitee.user.phone }})</div>
            <p>
              <span>累计收益: ¥{{item.invitee.user.profit}}</span>
              <span>余额: ¥{{item.invitee.user.balance}}</span>
            </p>
          </div>
        </div>
        <div class="to-order">查看消费></div>
      </div>
      <p style="border-top: 1px solid #f6f6f6; margin-top: 15px; padding-top: 10px;">
        <small style="float: right;color: red;" @click="onDelete(item.inviteId)">删除关系</small>
        <small>建立时间 {{ item.createTime }}</small>
      </p>
      <!-- <p>
        <small>过期时间 {{ item.expireTime }}</small>
      </p> -->
      <!-- <van-button type="default" @click="onClick(item)">查看受邀人消费明细</van-button>&nbsp;
      <van-button type="default" @click="onDelete(item.inviteId)">删除</van-button> -->
    </div>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      searchKey: '',
      page: 1,
      finished: false,
      loading: false,
      list: [],
      storeId: Cookies.get('storeId'),
      inviterId: useRoute.query.inviterId
    })
    const onClick = (item) => {
      useRouter.push({
        name: 'OrderList',
        query: {
          userPhone: item.invitee.user.phone,
          userName: item.invitee.name
        }
      })
    }
    const onDelete = (id) => {
      Dialog.confirm({
        title: '操作确认',
        message: '确认删除吗？'
      }).then(() => {
        console.log(id)
        post('/invite.delete', {
          inviteIds: id.toString().split(',')
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
      })
    }
    const onClear = () => {
      state.searchKey = ''
      reset()
    }
    const onSearch = (value) => {
      state.searchKey = value
      reset()
    }
    const getData = () => {
      post('/invite.list', {
        pageNum: state.page,
        storeId: state.storeId,
        phone: state.searchKey
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
      })
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
      getData()
    }
    const init = () => {
    }
    watch(() => useRoute.query, (to, previous) => {
    })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onSearch,
      onClear,
      onClick,
      onDelete
    }
  }
}
</script>

<style scoped>
.invite-item{
  width: 100%;
  position: relative;
}
.invite-item .user{
  margin-top: 5px;
  width: 100%;
  display: flex;
}
.invite-item .user .avatar{
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.invite-item .user .name{
  width: calc(100% - 45px);
  padding-left: 10px;
  box-sizing: border-box;
}

.invite-item .user .name div{
  font-size: 14px;
  font-weight: 500;
  margin-top: 1px;
}
.invite-item .user .name p{
  margin: 0;
  font-size: 13px;
  margin-top: 4px;
}
.invite-item .user .name p span{
  margin-right: 20px;
}
.invite-item .to-order{
  position: absolute;
  right: 0px;
  bottom: 10px;
  font-size: 14px;
  color: red;
}
</style>
